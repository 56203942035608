import * as React from "react"
import type { HeadFC } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import '../styles/global.scss'
import { OutboundLink } from "gatsby-plugin-google-gtag";


const IndexPage = () => {
  return (
    <main>
      <section style={{
        position: "relative",
        display: "block",
        padding: 0
      }}>
        <div style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          background: "none",
          backgroundImage: "linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 75%, #7C846A 100%)",
          zIndex: 1
          }}>
          <h1>
            <span className="headingCallout">Peppermint Lodge</span>
            <span className="headingMinor">East Jindabyne, in the NSW Snowy Mountains</span>
          </h1>
        </div>
        
        <StaticImage
          src="../images/queens_gambit.jpg"
          alt="A giant chess set in the foreground, garden bench behind, farmland and hills in the distance"
          loading="eager"
          placeholder="blurred"
          layout="fullWidth"
          style={{
            maxHeight: "80vh"
          }}
        />
      </section>

      <section className="textOnly callToAction">
        <p>
          <strong>Peppermint Lodge</strong> is a fun and spacious holiday home in East Jindabyne, and is the ideal base for your next <strong>Snowy Mountains</strong> adventure.
        </p>
        <OutboundLink href="https://airbnb.com/h/peppermint-lodge" className="button" target="_blank">Book Now</OutboundLink>
        <p>or <OutboundLink href="https://www.airbnb.com.au/contact_host/725052996932658671/send_message" target="_blank">Contact Us</OutboundLink> with any questions</p>
        <p>Three bedrooms, two bathrooms, for up to 8 people</p>
      </section>

      <section className="imageScroller">
        
        
        <StaticImage
          src="../images/06.jpg"
          alt="Lake Jindabyne seen through the living room window"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/07.jpg"
          alt="back deck attached to the second bedroom with plants and table setting"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/03.jpg"
          alt="pool table"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/08.jpg"
          alt="backyard"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />

      </section>
      
      <section className="textOnly">
        <h2>A place for fun</h2>
        <p>
          Whether you're having a cosy night after a day on the hill, having a more relaxed break, or hiding from the rain, there's always something to do at <strong>Peppermint Lodge</strong>.
        </p>
        <p>
          In the upstairs open plan living area there is an <strong>8-foot pool table</strong>, plenty of <strong>board games</strong>, a spacious living room with a <strong>big TV</strong>, plenty of comfy sofas and views all around.
        </p>
        <p>
          Outside, there's an outdoor <strong>table tennis table</strong> and a <strong>giant chess</strong> set to make the most of the sunny afternoons,
          and a <strong>fire pit</strong> to gather around as the sun sets.
        </p>
      </section>

      <section className="imageScroller">
        <StaticImage
          src="../images/29.jpg"
          alt="living room"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/25.jpg"
          alt="pool table"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/queens_gambit.jpg"
          alt="A giant chess set in the foreground, garden bench behind, farmland and hills in the distance"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/table_tennis.jpg"
          alt="table tennis table"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/games.jpg"
          alt="A collection of board games and puzzles"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
      </section>
      
      <section className="textOnly">
        <h2>Your home (for a little while)</h2>
        <p>At <strong>Peppermint Lodge</strong>, there's plenty to make you to feel right at home.</p>
        <p>
          Upstairs you'll find a <strong>massive kitchen</strong> with <strong>Smeg applicances</strong> including a wide oven, induction cooktop and dishwasher.
          There's also an <strong>espresso machine</strong> and a <strong>wine fridge</strong>. You and your fellow guests can gather around the large dining table, or
          enjoy a <strong>BBQ</strong> on the balcony with its outdoor bar setting and views of the farmland and hills to the East.
        </p>
        <p>
          Also available to all guests is the <strong>double garage</strong> with ski, board and bike storage, and the oversized <strong>laundry/drying room</strong>.
        </p>
      </section>

      <section className="imageScroller">
        
        <StaticImage
          src="../images/27.jpg"
          alt="kitchen with an island bench"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/23.jpg"
          alt="dining table"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/24.jpg"
          alt="outdoor bar table set and BBQ"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/26.jpg"
          alt="pantry with wine fridge"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/32.jpg"
          alt="garage"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/31.jpg"
          alt="laundry"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
      </section>
      
      <section className="textOnly">
        <h2>Relax and recover</h2>
        <p>
          There are three bedrooms to share at <strong>Peppermint Lodge</strong>.
          The <strong>master bedroom</strong> has a king size bed with a TV and hi fi stereo with record player.
          Downstairs, the <strong>second bedroom</strong> has a queen sized bed, study desk, and direct access to the backyard via a door onto the back deck.
          Finally, the <strong>third bedroom</strong> has one double bed and two single beds.
        </p>
        <p>
          The <strong>two bathrooms</strong> are both spacious and comfortable. The <strong>master bathroom</strong> has a luxurious rain shower, and the 
          <strong>second bathroom</strong> has a large tub and separate WC.
        </p>
      </section>

      <section className="imageScroller">
        
        <StaticImage
          src="../images/17.jpg"
          alt="master bedroom with king bed"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/21.jpg"
          alt="armchair and footstool in a corner of the master bedroom"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/22.jpg"
          alt="master bathoom"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/09.jpg"
          alt="second bedroom with queen bed"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/study_desk.jpg"
          alt="study desk in second bedroom"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/11.jpg"
          alt="main bathroom with bathtub"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/13.jpg"
          alt="basin and mirror in WC"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
        <StaticImage
          src="../images/14.jpg"
          alt="third bedroom with a double bed and two single beds"
          loading="lazy"
          placeholder="blurred"
          layout="constrained"
          height={300}
        />
      </section>
      
      <section className="textOnly">
        
        <h2>A beautiful setting</h2>
          <p>
            Some come to Jindabyne and the Snowy Mountains to ski, sleep, repeat. Others like to take in the spectacular environment, or discover
            a bit more about the local area. There really is something for everyone, all year round.
          </p>
          <p>
            Jindabyne is the gateway to the NSW ski resorts of <a href="https://www.thredbo.com.au/">Thredbo</a> and <a href="https://www.perisher.com.au/">Perisher</a>,
            the spectaular <a href="https://www.nationalparks.nsw.gov.au/visit-a-park/parks/kosciuszko-national-park">Kosciuszko National Park</a> with its inspiring walks amongst the highest peaks in Australia,
            <a href="https://www.trailforks.com/region/jindabyne/">mountain biking tracks</a> both near town and further afield,
            and the scenic Lake Jindabyne with plenty of activities <a href="https://www.sacredride.com.au/watersports-hire">on the water</a> and <a href="http://nswdiscgolf.com/jindabyne/">onshore</a>.
          </p>
          <p>
            Stay at <strong>Peppermint Lodge</strong> and you'll be just an 8-minute drive out of the main town, in a quiet neighbourhood with spectaular views.
            Head out the front door for a walk down to the lake, or hop on your bike and explore the many mountain bike tracks that continue all the
            way in to town and beyond.
          </p>
          <p>
            But with all that is provided at your accommodation, the question is, will you even want to leave?
          </p>
      </section>


      <section className="textOnly callToAction">
        <p>
          <strong>Peppermint Lodge</strong> is now exclusive to Airbnb.
        </p>
        <OutboundLink href="https://airbnb.com/h/peppermint-lodge" className="button" target="_blank">Book Now</OutboundLink>
        <p>or <OutboundLink href="https://www.airbnb.com.au/contact_host/725052996932658671/send_message" target="_blank">Contact Us</OutboundLink> with any questions</p>
      </section>
    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () =>
  <>
    <title>Peppermint Lodge - East Jindabyne Holiday Home</title>
    <meta name="description" content="Peppermint Lodge is a 3 bedroom 2 bathroom holiday home in East Jindabyne, near NSW skk resorts and Kosciuszko National Park" />
  </>